import React from "react";
import Seo from "../../components/seo";
import Industry from "../../layouts/Industries/Industry";
import {graphql, useStaticQuery} from 'gatsby';
import Layout from "../../components/layout";
import {Finance} from '../../components/svgs/industries';

const FinanceIndustryPageFr = () => {
  const data = useStaticQuery(graphql`
    query FinanceIndustryDatasFr {
      allContentfulSecteurs(filter: {contentful_id: {eq: "6ZaDyyZZ4mBcj8sAQtdw4s"}}) {
        edges {
          node {
            name
            permalink
            contentful_id
            advantages {
              title
              description
              icon
              visual {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
            installations {
              gatsbyImageData(layout: CONSTRAINED)
            }
            clients {
              gatsbyImageData(layout: CONSTRAINED)
            }
          }
        }
      }
    }
  `)

  return (
    <Layout 
    slider={false} 
    switchLink={data.allContentfulSecteurs.edges[1].node.permalink} 
    lang="fr-CA"
    title={data.allContentfulSecteurs.edges[0].node.name} 
    subTitle="L'affichage dynamique en"
    icon={<Finance/>}>
      <Seo 
        title="Affichage dynamique pour chaque succursale bancaire"
        description="Le système d'affichage dynamique d'Attractif permet aux banques de gérer les promotions en succursale et de garder le contrôle de chaque communication, pour un positionnement accru."
       />
      <Industry datas={data.allContentfulSecteurs.edges[0].node}/>
    </Layout>
  )
}

export default FinanceIndustryPageFr
